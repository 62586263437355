import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import BasicCard from '../../../../components/Cards/BasicCard';
import { generateEmptyProjectData, validateProject } from './services/newProjectFormService';
import { useClothingPreset } from '../../../../contexts/ClothingPresetContext';
import SplitButton from '../../../../components/Buttons/SplitButton';
import { IconsCatalog } from '../../../../components/IconsCatalog';
import { ContextMenuProvider } from '../../../../contexts/ContextMenuContext';
import ContextMenuViewer, { type ContextMenuItemType } from '../../../../components/ContextMenuViewer';
import ColorableRow from '../../../../components/Tables/ColorableRow';
import { type SearchProjectType, useProjects } from '../../../../contexts/ProjectsContext';
import { toast } from 'react-hot-toast-promise';
import Spinner from '../../../../components/Spinner';
import { useNavigate } from 'react-router';
import CircleButton from '../../../../components/Buttons/CircleButton';
import { displayCommonDateWithTime, displayTimePassedSinceDate } from '../../../../helpers/dateTime';
import PaginationControl from '../../../../components/PaginationControl';
import StickyMenuBar from '../../../../components/Menu/StickyMenuBar';
import ConfirmationModal from '../../../../components/Modals/ConfirmationModal';
import QuotaStatusBar from '../../../../components/QuotaStatusBar';

import { type ProjectType } from '../../../../types/ProjectType';
import { type ProjectBasicType } from '../../../../types/ProjectBasicType';
import { useUser } from '../../../../contexts/UserContext';
import {
  getUsersInSameCompany,
  isProjectAuthor,
  isProjectAuthorOrCompanyAccount
} from '../../../../contexts/services/userService';
import { useAppTranslation } from '../../../../contexts/TranslationContext';
import CreateListFormModal from './components/CreateListFormModal';
import FormCreateProject from './components/FormCreateProject';
import ClothingPresets from '../../Clothing/ClothingPresets';
import CreatePresetModal from './components/CreatePresetModal';
import { getProjectStatusFromFinalClientView, updateCachedProject } from './services/projectService';
import { Tooltip } from 'react-tooltip';
import TextAreaInputModal from '../../../../components/Modals/TextAreaInputModal';
import { type BasicUserIdentificationType } from '../../../../types/BasicUserIdentificationType';
import { useHttpRequest } from '../../../../contexts/HttpRequestContext';
import { getServerErrorMessageFromResponse } from '../../../../utils/helper';
import SellerApproveProjectButton, { type OnApproveParams } from './components/SellerApproveProjectButton';

export default function MyProjects() {
  const { httpConnection } = useHttpRequest();
  const { Translate, dateFnsLocale } = useAppTranslation();

  const sublistDefaultTitle = useMemo(() => Translate('labels.start-list'), [Translate]);

  const [project, setProject] = useState<ProjectType>(generateEmptyProjectData(sublistDefaultTitle));

  const navigate = useNavigate();

  const [selectedProjects, setSelectedProjects] = useState<ProjectBasicType[]>([]);
  const [selectionMode, setSelectionMode] = useState(false);
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);
  const [showCreationFormModal, setShowCreationFormModal] = useState(false);
  const [showCreationPresetModal, setShowCreationPresetModal] = useState(false);
  const [showSearchForm, setShowSearchForm] = useState(false);
  const [creatingOrEditingProject, setCreatingOrEditingProject] = useState(false);
  const [projectToManagePendings, setProjectToManagePendings] = useState<ProjectBasicType | null>(null);
  const [isSavingProjectPendings, setIsSavingProjectPendings] = useState(false);
  const [isLoadingUsers, setIsLoadingUsers] = useState(true);
  const [usersInSameCompany, setUsersInSameCompany] = useState<BasicUserIdentificationType[]>([]);

  const [searchSettings, setSearchSettings] = useState<SearchProjectType>({
    searchText: '',
    searchFilter: '',
    userId: -1,
    pageNumber: 1
  });

  const [isFetching, setIsFetching] = useState<boolean>(false);

  const { user, isEmployeeAccount, isCompanyAccount } = useUser();
  const { presets } = useClothingPreset();

  const isSearchFormVisible = useMemo(() => (showSearchForm ? 'd-block' : 'd-none'), [showSearchForm]);
  const ref_SearchInput = useRef<HTMLInputElement>(null);

  const {
    projects,
    setProjects,
    createProject,
    updateProjectBasicData,
    deleteProjects,
    paginationControl,
    searchProject,
    loadProjects,
    downloadAllProjectsAsZip
  } = useProjects();

  const handleAddProject = useCallback(async () => {
    try {
      validateProject(project, Translate);
      setCreatingOrEditingProject(true);

      const createdProject = await createProject(project);
      toast.success(Translate('toast.project-created'));

      navigate('view', {
        state: { isNew: true, projectData: createdProject }
      });
    } catch (err) {
      toast.error(getServerErrorMessageFromResponse(err));
    } finally {
      setCreatingOrEditingProject(false);
    }
  }, [Translate, createProject, navigate, project]);

  const handleEditProject = useCallback(async () => {
    try {
      validateProject(project, Translate);
      setCreatingOrEditingProject(true);

      if (!project?.id || !project.created_at || !project.updated_at) return;

      const { sublists, ...projectBasicData } = project;

      await updateProjectBasicData({
        ...projectBasicData,
        id: project.id,
        created_at: project.created_at,
        updated_at: project.updated_at
      });

      setProject(generateEmptyProjectData(sublistDefaultTitle));
      toast.success(Translate('toast.project-updated'));
    } catch (err) {
      toast.error(getServerErrorMessageFromResponse(err));
    } finally {
      setCreatingOrEditingProject(false);
    }
  }, [Translate, project, sublistDefaultTitle, updateProjectBasicData]);

  const handleCancelEditProject = useCallback(() => {
    setProject(generateEmptyProjectData(sublistDefaultTitle));
    toast.success(Translate('toast.editing-canceled'));
  }, [Translate, sublistDefaultTitle]);

  const handleSubmitProject = useCallback(async () => {
    if (!project?.id) handleAddProject();
    else handleEditProject();
  }, [handleAddProject, handleEditProject, project?.id]);

  const openProject = useCallback(
    (project: ProjectBasicType) => {
      navigate('view', {
        state: { isNew: false, projectId: project.id }
      });
    },
    [navigate]
  );

  const handleSearchProject = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      try {
        setIsFetching(true);
        await searchProject(searchSettings);
      } catch (err) {
        toast.error(getServerErrorMessageFromResponse(err));
      } finally {
        setIsFetching(false);
      }
    },
    [searchProject, searchSettings]
  );

  const handleClearSearch = useCallback(async () => {
    setIsFetching(true);
    setSearchSettings({ searchText: '', searchFilter: '', userId: -1, pageNumber: 1 });
    await loadProjects(1);
    setIsFetching(false);
  }, [loadProjects]);

  const handleExitSelectionMode = useCallback(() => {
    setSelectionMode(false);
    setSelectedProjects([]);
  }, []);

  const handleNavigateToPage = useCallback(
    async (pageNumber: number) => {
      setIsFetching(true);
      if (!paginationControl.isSearchResult) await loadProjects(pageNumber);
      else {
        await searchProject({ ...searchSettings, pageNumber });
      }

      setIsFetching(false);
      handleExitSelectionMode();
    },
    [handleExitSelectionMode, loadProjects, paginationControl.isSearchResult, searchProject, searchSettings]
  );

  const handleCheckProject = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, project: ProjectBasicType) => {
      const { checked } = event.target;

      if (checked) {
        setSelectedProjects([...selectedProjects, project]);
        return;
      }

      const updatedSelectedProjects = selectedProjects.filter(currentProjects => currentProjects.id !== project.id);
      setSelectedProjects(updatedSelectedProjects);
    },
    [selectedProjects]
  );

  const handleCancelDeleteSelectedPresets = useCallback(() => {
    setSelectedProjects([]);
    setShowDeleteConfirmationModal(false);
  }, []);

  const handleConfirmDeleteSelectedProjects = useCallback(() => {
    toast.promise(deleteProjects(selectedProjects), {
      loading: Translate('progress.deleting'),
      success: () => {
        setSelectedProjects([]);
        return Translate('toast.projects-deleted');
      },
      error: err => {
        setSelectedProjects([]);
        return getServerErrorMessageFromResponse(err);
      }
    });
  }, [Translate, deleteProjects, selectedProjects]);

  const handleCheckAllProjects = useCallback((event: React.ChangeEvent<HTMLInputElement>, projects: ProjectBasicType[]) => {
    const { checked } = event.target;

    if (checked) setSelectedProjects([...projects]);
    else setSelectedProjects([]);
  }, []);

  const handleDownloadAllProjects = useCallback(() => {
    toast.promise(downloadAllProjectsAsZip(), {
      loading: Translate('progress.download-informations'),
      success: () => {
        return Translate('toast.download-completed');
      },
      error: getServerErrorMessageFromResponse
    });
  }, [Translate, downloadAllProjectsAsZip]);

  const handleUpdateProjectPendings = useCallback(
    (pendings: string) => {
      if (!projectToManagePendings) return;

      const promise = updateProjectBasicData({ ...projectToManagePendings, pendings });

      setIsSavingProjectPendings(true);

      toast.promise(promise, {
        loading: Translate('progress.loading'),
        success: () => {
          setProjectToManagePendings(null);
          return Translate('toast.done');
        },
        error: getServerErrorMessageFromResponse,
        finally: () => {
          setIsSavingProjectPendings(false);
        }
      });
    },
    [Translate, projectToManagePendings, updateProjectBasicData]
  );

  const handleOnApproveProjectBySeller = useCallback(({ project, response }: OnApproveParams) => {
    const updatedProject = { ...project, ...response };
    const updatedCachedProjectsList = updateCachedProject(updatedProject, projects);
    setProjects(updatedCachedProjectsList);
  }, [projects, setProjects]);

  const contextMenuProjectOptions = useMemo<ContextMenuItemType[]>(
    () => [
      {
        name: Translate('actions.edit'),
        icon: IconsCatalog.pen,
        handleClick: selectedOption => {
          if (!isProjectAuthor(selectedOption as ProjectType, user)) {
            toast.error(Translate('error.cant-edit-project-from-other-author'));
            return;
          }

          setProject(selectedOption as ProjectType);
          setShowCreationFormModal(true);
        }
      },
      {
        name: Translate('actions.delete'),
        icon: IconsCatalog.trash,
        handleClick: selectedOption => {
          if (!isProjectAuthorOrCompanyAccount(selectedOption as ProjectType, user)) {
            toast.error(Translate('error.cant-delete-project-from-other-author'));
            return;
          }

          setSelectedProjects([selectedOption as ProjectBasicType]);
          setShowDeleteConfirmationModal(true);
        }
      },
      {
        name: Translate('actions.selection-mode'),
        icon: IconsCatalog.checkSquare,
        handleClick: () => {
          handleCancelEditProject();
          setSelectionMode(true);
        }
      }
    ],
    [Translate, handleCancelEditProject, user]
  );

  const isEditingProject = useMemo(() => !!project.id, [project]);

  useEffect(() => {
    getUsersInSameCompany(httpConnection)
      .then(users => {
        setUsersInSameCompany(users);
      })
      .catch(err => {
        const { message } = err as Error;
        toast.error(message);
      })
      .finally(() => {
        setIsLoadingUsers(false);
      });
  }, [httpConnection]);

  if (!user) return <></>;

  return (
    <React.Fragment>
      <ConfirmationModal
        title={Translate('labels.confirmation')}
        message={Translate('description.confirm-delete-project')}
        style='danger'
        visible={showDeleteConfirmationModal}
        handleClose={handleCancelDeleteSelectedPresets}
        handleConfirm={() => {
          setShowDeleteConfirmationModal(false);
          handleConfirmDeleteSelectedProjects();
        }}
      />

      <CreateListFormModal
        hideFooter
        disableEscToClose={true}
        visible={showCreationFormModal}
        title={Translate('actions.create-project')}
        handleClose={() => {
          setShowCreationFormModal(false);
          setProject(generateEmptyProjectData(sublistDefaultTitle));
        }}
        handleConfirm={() => { }}
      >
        <FormCreateProject
          project={project}
          presets={presets}
          creatingOrEditingProject={creatingOrEditingProject}
          setProject={setProject}
          handleSubmitProject={handleSubmitProject}
          handleCancelEditProject={handleCancelEditProject}
          handleOpenCreatePreset={() => {
            setShowCreationPresetModal(true);
          }}
        />
      </CreateListFormModal>

      <CreatePresetModal
        hideFooter
        disableEscToClose={true}
        visible={showCreationPresetModal}
        title={Translate('modal.presets-shortcut')}
        handleClose={() => {
          setShowCreationPresetModal(false);
        }}
        handleConfirm={() => {
          setShowCreationPresetModal(false);
        }}
      >
        <ClothingPresets />
      </CreatePresetModal>

      {!!projectToManagePendings && (
        <TextAreaInputModal
          visible
          disableButtons={isSavingProjectPendings}
          style='primary'
          title='Pendências'
          label='Anote problemas de produção, lembretes ou demais pendências existentes no projeto.'
          currentValue={projectToManagePendings?.pendings ?? ''}
          handleClose={() => {
            setProjectToManagePendings(null);
          }}
          handleConfirm={pendings => {
            handleUpdateProjectPendings(pendings);
          }}
        />
      )}

      <div className='row'>
        <div className='col'>
          <h1 className='h3 mb-2 text-gray-800'>{Translate('labels.my-projects')}</h1>
          <p className='mb-4'>{Translate('description.projects-page-header-description')}</p>
        </div>

        <div className='col-auto'>
          <SplitButton
            simulateLabelMarginTop
            disabled={paginationControl.total === user.list_max_projects}
            icon={IconsCatalog.plus}
            color='primary'
            handleClick={() => {
              setShowCreationFormModal(true);
            }}
          />

          <SplitButton
            marginLeft
            simulateLabelMarginTop
            icon={IconsCatalog.search}
            color='primary'
            handleClick={() => {
              setShowSearchForm(prevState => {
                const visible = !prevState;
                if (visible) setTimeout(() => ref_SearchInput.current?.focus(), 250);
                return visible;
              });
            }}
          />

          <SplitButton
            marginLeft
            simulateLabelMarginTop
            icon={IconsCatalog.download}
            color='success'
            handleClick={handleDownloadAllProjects}
          />

          <SplitButton
            marginLeft
            simulateLabelMarginTop
            icon={IconsCatalog.calendarDays}
            color='primary'
            handleClick={() => {
              navigate('schedule');
            }}
          />
        </div>
      </div>

      {selectionMode && (
        <StickyMenuBar countSelectedItems={selectedProjects.length}>
          <SplitButton
            size='sm'
            icon={IconsCatalog.times}
            title={Translate('actions.cancel')}
            color='light'
            handleClick={handleExitSelectionMode}
          />

          <SplitButton
            size='sm'
            icon={IconsCatalog.trash}
            title={Translate('actions.delete-selected')}
            color='danger'
            marginLeft
            handleClick={() => {
              setShowDeleteConfirmationModal(true);
            }}
          />
        </StickyMenuBar>
      )}

      {paginationControl.total === user.list_max_projects && (
        <div className='alert alert-warning'>{Translate('status.cant-create-new-projects-quota-reached')}</div>
      )}

      <BasicCard title={Translate('labels.orders')}>
        {!paginationControl.isSearchResult && !isEmployeeAccount && (
          <QuotaStatusBar
            value={paginationControl.total ?? 0}
            maxValue={user.list_max_projects}
            message={Translate('status.project-quota-message')}
          />
        )}

        {/* SEARCH SECTION */}
        <section className={`${isSearchFormVisible}`}>
          <form onSubmit={handleSearchProject}>
            <h5 className='mt-4'>{Translate('labels.search-field')}</h5>

            <div className='input-group mb-3'>
              <input
                ref={ref_SearchInput}
                type='text'
                value={searchSettings.searchText}
                disabled={isFetching}
                className='form-control'
                placeholder={Translate('labels.search-by-name-or-project-order-number')}
                onChange={({ target }) => {
                  setSearchSettings({ ...searchSettings, searchText: target.value });
                }}
              />
              <div className='input-group-append'>
                <select
                  className='form-control custom-select rounded-0'
                  value={searchSettings.searchFilter}
                  disabled={isFetching}
                  onChange={({ target }) => {
                    setSearchSettings({ ...searchSettings, searchFilter: target.value });
                  }}
                >
                  <option value=''>{Translate('labels.projects-filter-all-projects')}</option>
                  <option value='private_projects'>{Translate('labels.projects-filter-private-projects')}</option>
                  <option value='final_client_saved_changes'>{Translate('labels.projects-filter-final-client-saved-changes')}</option>
                  <option value='approved_projects'>{Translate('labels.approved-projects')}</option>
                </select>

                <select
                  className='form-control custom-select rounded-0'
                  value={searchSettings.userId}
                  disabled={isFetching || isLoadingUsers}
                  onChange={({ target }) => {
                    setSearchSettings({ ...searchSettings, userId: parseInt(target.value) });
                  }}
                >
                  <option value='-1'>{Translate('labels.all-employees')}</option>
                  {usersInSameCompany.map((user, index) => (
                    <option value={user.id} key={index}>
                      {user.name}
                    </option>
                  ))}
                </select>

                <button className='btn btn-outline-primary' type='submit' disabled={isFetching}>
                  {Translate('actions.search')}
                </button>

                <button
                  className='btn btn-outline-danger'
                  type='button'
                  onClick={handleClearSearch}
                  disabled={!paginationControl.isSearchResult && (searchSettings.searchText === '' || isFetching)}
                >
                  {Translate('actions.clear')}
                </button>
              </div>
            </div>
          </form>
        </section>

        {isFetching && <Spinner />}

        {!isFetching && (
          <React.Fragment>
            {paginationControl.isSearchResult && (
              <span className='d-block mb-3'>
                {Translate('status.project-search-result-message').replace(
                  '{projectsCount}',
                  paginationControl.total.toString()
                )}
              </span>
            )}

            <ContextMenuProvider>
              <ContextMenuViewer options={contextMenuProjectOptions} disabled={selectionMode || isEditingProject} />

              <small className='mb-3 d-block p-1 border border-left-success'>
                {Translate('description.projects-left-green-border-meaning')}
              </small>

              <table className='table table-bordered' width='100%'>
                <thead>
                  <tr>
                    {selectionMode && (
                      <th className='text-center'>
                        <input
                          type='checkbox'
                          defaultChecked={false}
                          onChange={event => {
                            handleCheckAllProjects(event, projects);
                          }}
                        />
                      </th>
                    )}

                    <th className='text-center'>{Translate('labels.production-order-abbrev')}</th>
                    <th>{Translate('labels.name')}</th>
                    <th>{Translate('labels.created')}</th>
                    <th>{Translate('labels.edited')}</th>
                    <th>{Translate('labels.delivery-forecast')}</th>
                    <th className='text-center'>{Translate('labels.final-client')}</th>
                    <th className='text-center'>{Translate('labels.pending')}</th>
                    <th className='text-center'>{Translate('status.approved-at')}</th>
                    <th className='text-center' style={{ width: 0 }}>
                      {Translate('actions.open')}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {projects.map((project, index) => {
                    const finalClientProjectStatus = getProjectStatusFromFinalClientView(project, Translate);

                    return (
                      <ColorableRow
                        testId={`row-with-context-menu-project-${project.id}`}
                        data={project}
                        isSelected={selectedProjects.includes(project)}
                        key={index}
                        marked={{ show: isCompanyAccount && isProjectAuthor(project, user), color: 'success' }}
                        showBackgroundColor={!!project.approved_at}
                        color='#b8f5d044'
                      >
                        {selectionMode && (
                          <td width={50} className='text-center align-middle'>
                            <input
                              type='checkbox'
                              checked={selectedProjects.includes(project)}
                              onChange={event => {
                                handleCheckProject(event, project);
                              }}
                            />
                          </td>
                        )}

                        <td className='align-middle text-center table-column-fit'>
                          {!!project.order_number && project.order_number > 0 ? project.order_number : '-'}
                        </td>

                        <td className='align-middle' data-testid={`list-project-item-${project.id}`}>
                          {project.name}
                        </td>

                        <td className='align-middle'>{project.author.name}</td>

                        <td className='align-middle'>
                          <Tooltip id={`tooltip-edited-by-pid-${project.id}`} opacity={1}>
                            {project?.last_user_edited?.name ?? Translate('status.no-editings')}
                          </Tooltip>

                          <div
                            data-tooltip-id={`tooltip-edited-by-pid-${project.id}`}
                            data-tooltip-delay-show={250}
                            data-tooltip-variant='dark'
                            data-tooltip-place='left'
                          >
                            {project.updated_at
                              ? displayTimePassedSinceDate(new Date(project.updated_at), dateFnsLocale)
                              : Translate('status.undefined')}
                          </div>
                        </td>

                        <td className='align-middle'>
                          {project.delivery_date
                            ? displayTimePassedSinceDate(new Date(project.delivery_date), dateFnsLocale)
                            : Translate('status.undefined')}
                        </td>

                        <td className='align-middle text-center'>
                          <span className={`badge badge-${finalClientProjectStatus.style}`}>
                            {finalClientProjectStatus.text.toUpperCase()}
                          </span>
                        </td>

                        <td className='align-middle text-center'>
                          <SplitButton
                            disabled={isSavingProjectPendings}
                            icon={IconsCatalog.pen}
                            size='sm'
                            color={project.pendings ? 'danger' : 'secondary'}
                            handleClick={() => {
                              setProjectToManagePendings(project);
                            }}
                          />
                        </td>

                        <td className='align-middle text-center'>
                          <Tooltip id={`tooltip-approved-by-pid-${project.id}`} opacity={1}>
                            {project.approved_by}
                          </Tooltip>

                          <div
                            data-tooltip-id={`tooltip-approved-by-pid-${project.id}`}
                            data-tooltip-delay-show={250}
                            data-tooltip-variant='dark'
                            data-tooltip-place='left'
                          >
                            {project.approved_at && displayCommonDateWithTime(new Date(project.approved_at), dateFnsLocale)}
                          </div>

                          {!project.approved_at && (
                            <SellerApproveProjectButton
                              project={project}
                              Translate={Translate}
                              httpConnection={httpConnection}
                              onApprove={handleOnApproveProjectBySeller}
                            />
                          )}
                        </td>

                        <td className='text-center'>
                          <CircleButton
                            size='sm'
                            testId={`button-open-project-${project.id}`}
                            color='secondary'
                            icon={IconsCatalog.eye}
                            handleClick={() => {
                              openProject(project);
                            }}
                          />
                        </td>
                      </ColorableRow>
                    );
                  })}

                  {projects.length === 0 && (
                    <tr className='text-center'>
                      <td colSpan={99} data-testid='no-projects-found-message'>
                        {Translate('status.no-projects-found')}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>

              <PaginationControl
                totalPages={paginationControl.last_page}
                activePage={paginationControl.current_page}
                handleNavigateToPage={handleNavigateToPage}
              />
            </ContextMenuProvider>
          </React.Fragment>
        )}
      </BasicCard>
    </React.Fragment>
  );
}
