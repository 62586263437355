import _ from 'lodash';
import { useFormContext } from 'react-hook-form';
import { type Dispatch, type SetStateAction, useEffect, useMemo, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';

import { type AxiosError } from 'axios';
import { toast } from 'react-toastify';

import ModalBase from '../../../../../../components/Modals/ModalBase';
import { createEntity, updateEntity } from '../../api';
import { type Sections } from '../SectionsOptions';
import { getLabels } from '../../helpers/getLabels';
import { CreateOrUpdateForm } from './fragments/CreateOrUpdateForm';
import { RawMaterial } from './fragments/RawMaterial';
import { Fraccao } from './fragments/Fraccao';

type RegisterFormProps = {
  section: Sections;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  showModal: boolean;
};

export function RegisterForm({ section, setShowModal, showModal }: RegisterFormProps) {
  const [selectedSection, setSelectedSection] = useState<Sections | 'raw_material' | 'faccao' | null>(null);
  const methods = useFormContext();

  const queryClient = useQueryClient();
  const createClientMutation = useMutation(
    async (data: any) => {
      await createEntity.execute(data);
    },
    {
      onSuccess: () => {
        setShowModal(false);
        methods.reset();
        queryClient.invalidateQueries(['entities']);
      },
      onError: (error: AxiosError) => {
        toast.error((error?.response?.data as { message: string })?.message);
      }
    }
  );

  const updateClientMutation = useMutation(
    async (data: any) => {
      await updateEntity.execute(data, methods.watch('id'));
    },
    {
      onSuccess: () => {
        setShowModal(false);
        toast.success('Atualizado com sucesso');
        methods.reset();
        queryClient.invalidateQueries(['entities']);
      },
      onError: (error: AxiosError) => {
        toast.error((error?.response?.data as { message: string })?.message);
      }
    }
  );

  const labels = useMemo(() => {
    return getLabels(section);
  }, [section]);

  const headerButton = useMemo(() => {
    if (section === 'supplier') {
      return (
        <div className='row d-flex justify-content-center mb-4'>
          <div className='btn-group'>
            <button
              className='btn btn-primary'
              type='button'
              disabled={selectedSection === 'supplier'}
              onClick={() => {
                setSelectedSection('supplier');
              }}
            >
              Fornecedor
            </button>
            <button
              className='btn btn-primary'
              type='button'
              disabled={selectedSection === 'raw_material'}
              onClick={() => {
                setSelectedSection('raw_material');
              }}
            >
              Matéria prima
            </button>
          </div>
        </div>
      );
    }

    if (section === 'seamstress') {
      return (
        <div className='row d-flex justify-content-center mb-4'>
          <div className='btn-group'>
            <button
              className='btn btn-primary'
              type='button'
              disabled={selectedSection === 'seamstress'}
              onClick={() => {
                setSelectedSection('seamstress');
              }}
            >
              Costureira
            </button>
            <button
              className='btn btn-primary'
              type='button'
              disabled={selectedSection === 'faccao'}
              onClick={() => {
                setSelectedSection('faccao');
              }}
            >
              Facção
            </button>
          </div>
        </div>
      );
    }

    return null;
  }, [section, selectedSection]);

  const content = useMemo(() => {
    if (selectedSection === null) {
      return null;
    }

    if (selectedSection === 'raw_material') {
      return <RawMaterial />;
    }

    if (selectedSection === 'faccao') {
      return <Fraccao />;
    }

    return <CreateOrUpdateForm section={selectedSection} />;
  }, [selectedSection]);

  useEffect(() => {
    setSelectedSection(section);

    return () => {
      setSelectedSection(null);
    };
  }, [section]);

  return (
    <>
      <ModalBase
        disableClickOutsideToClose
        style='primary'
        title={`Adicionar ${labels.title}`}
        visible={showModal}
        handleClose={() => {
          methods.reset();
          setShowModal(false);
        }}
        handleConfirm={function (): void {}}
        hideFooter={true}
      >
        <form
          onSubmit={methods.handleSubmit(data => {
            if (!_.isNil(methods.watch('id'))) {
              updateClientMutation.mutate({ ...data, type: section });
              return;
            }

            createClientMutation.mutate({ ...data, type: section });
          })}
        >
          {headerButton}

          {content}

          <div className='w-100 d-flex justify-content-end'>
            <button className='btn btn-primary'>
              <i className='fas fa-save'></i> {_.isNil(methods.watch('id')) ? <span>Salvar</span> : <span>Atualizar</span>}
            </button>
          </div>
        </form>
      </ModalBase>

      <button
        className='btn btn-sm btn-primary'
        type='button'
        onClick={() => {
          setShowModal(true);
        }}
        disabled={createClientMutation.isLoading || updateClientMutation.isLoading}
      >
        <i className={labels.icon} /> <span>Adicionar {labels.title}</span>
      </button>
    </>
  );
}
