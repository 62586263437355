import React, { type SetStateAction, useCallback, useState, useEffect } from 'react';
import ReactGA from 'react-ga4';

import { type ClothingModelType } from '../../../../../../types/ClothingModelType';
import { type ListContentParsedResultData } from '../../../../../../types/ListContentParsedResultData';
import { type SublistType } from '../../../../../../types/SublistTypes';
import { type SortByClothingSizeParams } from '../../../../../../types/SortByClothingSizeParams';

import BasicCard from '../../../../../../components/Cards/BasicCard';
import TextAreaInput from '../../../../../../components/Forms/TextAreaInput';
import ComboBox from '../../../../../../components/Forms/ComboBox';
import SplitButton from '../../../../../../components/Buttons/SplitButton';
import Checkbox from '../../../../../../components/Forms/Checkbox';
import { extractSelectionIndexNumber, processParsedContent } from '../../services/orderBatchService';
import { generateSublistOptions } from '../../services/orderFormService';
import { IconsCatalog } from '../../../../../../components/IconsCatalog';
import { toast } from 'react-hot-toast-promise';
import { addOrders } from '../../../services/orderService';
import { findFirstShirtIndexInModels } from '../../services/modelService';
import { useSettings } from '../../../../../../contexts/SettingsContext';
import GenderPrefix from '../../../OrderSettings/GenderPrefix';
import { useAppTranslation } from '../../../../../../contexts/TranslationContext';
import { saveListPastedInputContent } from './components/services/pastedListEditorService';
import { useHttpRequest } from '../../../../../../contexts/HttpRequestContext';
import ListContentTableView from '../../components/ListContentTableView';
import { isDev } from '../../../../../../utils/helper';

type PastedListEditorType = {
  sublists: SublistType[];
  setSublists: React.Dispatch<SetStateAction<SublistType[]>>;
  importedModels: ClothingModelType[];
  handleSortByClothingSize: (params: SortByClothingSizeParams) => void;
};

type AutoSortingSettingsType = {
  enabled: boolean;
  type: undefined | 'asc' | 'desc';
};

export default function PastedListEditor({
  sublists,
  setSublists,
  importedModels,
  handleSortByClothingSize
}: PastedListEditorType) {
  const [readyToEdit, setReadyToEdit] = useState<boolean>(false);
  const [rawListPasted, setRawListPasted] = useState('');
  const [listContentParsingErrors, setListContentParsingErrors] = useState<string[][]>([]);
  const [listContentParsed, setListContentParsed] = useState<ListContentParsedResultData[]>([]);
  const [selectedClothesToGenerate, setSelectedClothesToGenerate] = useState<ClothingModelType[]>([]);
  const [targetSublistIndex, setTargetSublistIndex] = useState(0);
  const [autoSortSettings, setAutoSortingSettings] = useState<AutoSortingSettingsType>({ enabled: false, type: undefined });

  const { preferences } = useSettings();
  const { Translate } = useAppTranslation();
  const { httpConnection } = useHttpRequest();

  const handleCheckClothesToGenerate = useCallback(
    (targetClothe: ClothingModelType) => {
      if (!selectedClothesToGenerate.includes(targetClothe)) {
        setSelectedClothesToGenerate([...selectedClothesToGenerate, targetClothe]);
        return;
      }

      const filtered = selectedClothesToGenerate.filter(currentClothe => currentClothe.id !== targetClothe.id);
      setSelectedClothesToGenerate(filtered);
    },
    [selectedClothesToGenerate]
  );

  const cleanUp = useCallback(() => {
    setRawListPasted('');
    setReadyToEdit(false);
    setListContentParsed([]);
    setListContentParsingErrors([]);
  }, []);

  const handleAddBatchOrders = useCallback(() => {
    if (!isDev()) {
      ReactGA.event({
        category: 'create',
        action: 'create-list-from-whatsapp-text'
      });
    }

    const { hasError, errorsFound, generatedOrders } = processParsedContent({
      sublistUUID: sublists[targetSublistIndex].uuid,
      listContentParsed,
      selectedClothesToGenerate,
      importedModels,
      preferences,
      Translate
    });

    setListContentParsingErrors(errorsFound);
    saveListPastedInputContent(rawListPasted, httpConnection);

    if (hasError) {
      toast.error(Translate('error.add-batch-orders-contains-error'));
      return;
    }

    const updatedSublists = addOrders(sublists, targetSublistIndex, generatedOrders);

    setAutoSortingSettings({ enabled: autoSortSettings.type !== undefined, type: autoSortSettings.type });
    setSublists(updatedSublists);
    cleanUp();

    toast.success(Translate('toast.list-generated'));
  }, [sublists, targetSublistIndex, listContentParsed, selectedClothesToGenerate, importedModels, preferences, Translate, rawListPasted, httpConnection, autoSortSettings.type, setSublists, cleanUp]);

  useEffect(() => {
    if (!autoSortSettings.enabled) return;

    const clothingIndexToSortBy = findFirstShirtIndexInModels({ models: importedModels });

    handleSortByClothingSize({
      sublist: sublists[targetSublistIndex],
      clotheIndex: clothingIndexToSortBy,
      forceSortingType: autoSortSettings.type
    });

    setAutoSortingSettings({ enabled: false, type: undefined });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoSortSettings, sublists]);

  return (
    <BasicCard>
      <GenderPrefix />
      <div className='row'>
        <div className='col-12'>
          {!readyToEdit && (
            <React.Fragment>
              <TextAreaInput
                id='raw-list'
                label={Translate('labels.data-input')}
                value={rawListPasted}
                placeholder=''
                rows={10}
                tabIndex={1}
                maxLength={30000}
                handleChange={({ target }) => {
                  setRawListPasted(target.value);
                }}
              />

              <SplitButton
                color='primary'
                icon={IconsCatalog.forms}
                title='Preparar dados inseridos'
                size='sm'
                handleClick={() => {
                  setReadyToEdit(true);
                }}
              />
            </React.Fragment>
          )}

          {
            readyToEdit && (
              <React.Fragment>
                <ListContentTableView
                  data={rawListPasted.trim()}
                  importedModels={importedModels}
                  listContentParsingErrors={listContentParsingErrors}
                  handleChange={(parsedList) => {
                    setListContentParsed(parsedList);
                  }}
                />

                <SplitButton
                  color='danger'
                  icon={IconsCatalog.trash}
                  title={Translate('actions.discard-data')}
                  size='sm'
                  handleClick={cleanUp}
                />
              </React.Fragment>
            )
          }

        </div>
      </div>

      <div className='row mt-3'>
        <div className='col-auto'>
          <label>{Translate('labels.which-clothes')}</label>

          {importedModels.map((clothe, index) => {
            const selectionIndex = extractSelectionIndexNumber(selectedClothesToGenerate, clothe);
            return (
              <Checkbox
                key={index}
                tabIndex={2}
                id={`imported-model-${index}`}
                name='selected-clothes'
                checked={selectedClothesToGenerate.includes(clothe)}
                label={`${selectionIndex} ${clothe.name}`}
                handleChange={() => {
                  handleCheckClothesToGenerate(clothe);
                }}
              />
            );
          })}
        </div>

        <div className='col-auto'>
          <ComboBox
            tabIndex={3}
            id='target-sublist'
            value={targetSublistIndex.toString()}
            header={Translate('labels.sublists')}
            handleChange={({ target }) => {
              setTargetSublistIndex(parseInt(target.value));
            }}
            data={generateSublistOptions(sublists)}
          />
        </div>

        <div className='col-auto'>
          <ComboBox
            tabIndex={4}
            id='sorting-type'
            value={autoSortSettings.type?.toString() ?? ''}
            header={Translate('labels.sorting')}
            handleChange={({ target }) => {
              setAutoSortingSettings({
                enabled: false,
                type: target.value.trim() as AutoSortingSettingsType['type']
              });
            }}
            data={[
              {
                label: Translate('labels.sorting-none'),
                value: ''
              },
              {
                label: Translate('labels.sorting-ltg'),
                value: 'asc'
              },
              {
                label: Translate('labels.sorting-gtl'),
                value: 'desc'
              }
            ]}
          />
        </div>
      </div>

      <div className='row'>
        <div className='col'>
          <SplitButton
            color='success'
            icon={IconsCatalog.play}
            title={Translate('actions.process')}
            tabIndex={5}
            disabled={selectedClothesToGenerate.length === 0 || listContentParsed.length === 0}
            handleClick={handleAddBatchOrders}
          />
        </div>
      </div>
    </BasicCard>
  );
}
