import { v4 as uuidv4 } from 'uuid';
import { type ClothingModelType } from '../../../../../types/ClothingModelType';
import { type ListContentParsedResultData } from '../../../../../types/ListContentParsedResultData';
import { type ClothingProductionData, type Order } from '../../../../../types/OrderTypes';
import { type UserPreferences } from '../../../../../types/UserPreferences';
import { type TFunction } from 'i18next';
import { extractClothingSizesInGenderFromImportedModels } from '../../../Clothing/MyClothingModels/services/clothingModelsService';

type GetGenderFromClothingSizeParams = {
  size: string;
  preferences: UserPreferences;
  availableSizesInProjectForChildish: string[];
}

export const getGenderFromClothingSize = ({ size, preferences, availableSizesInProjectForChildish }: GetGenderFromClothingSizeParams): Order['gender'] => {
  const femalePrefix = preferences.gender_female_prefix;
  const femalePrefixRegExp = new RegExp(`^${femalePrefix}`, 'i');

  const hasBabyLookPrefix = femalePrefixRegExp.test(size);
  if (hasBabyLookPrefix) return 'female';

  const childishSufix = preferences.gender_childish_sufix;
  const childishSufixRegExp = new RegExp(`${childishSufix}$`, 'i');

  const hasAgeSuffix = childishSufixRegExp.test(size);
  if (hasAgeSuffix) return 'childish';

  // consider other ways to write clothing sizes for children
  if (childishSufix === null) {
    // check if the size is present in any of the models for children
    if (availableSizesInProjectForChildish.includes(size.toUpperCase())) return 'childish';
  }

  // If no conditions above matches, return male by default
  return 'male';
};

export const extractSelectionIndexNumber = (models: ClothingModelType[], modelToFind: ClothingModelType): string => {
  const index = models.findIndex(model => model.id === modelToFind.id);
  return index > -1 ? `${index + 1}° -` : '';
};

type ValidationParams = {
  sizes: string[];
  gender: Order['gender'];
  selectedClothesToGenerate: ClothingModelType[];
  Translate: TFunction;
}

type ValidationResult = {
  errors: string[];
  generatedClothingData: ClothingProductionData[];
}

const validateInformations = ({ sizes, gender, selectedClothesToGenerate, Translate }: ValidationParams): ValidationResult => {
  const errors: string[] = [];
  const generatedClothingData: ClothingProductionData[] = [];

  for (let i = 0; i < sizes.length; i++) {
    const size = sizes[i];
    const targetModel = selectedClothesToGenerate[i];

    if (!targetModel) continue;

    const targetModelPrices = targetModel.informations[gender];
    const targetSizeIndex = targetModelPrices.findIndex(budget => budget.size.toUpperCase() === size.toUpperCase());

    if (targetSizeIndex === -1 && size !== '') {
      errors.push(
        Translate('error.order-batch-size-not-found')
          .replace('{size}', size)
          .replace('{name}', targetModel.name)
          .replace('{gender}', Translate(`labels.${gender}`))
      );
      continue;
    }

    // Generate empty clothe if size is empty
    if (targetSizeIndex === -1 || size === '') {
      generatedClothingData.push({ modelId: targetModel.id, quantity: 0, sizeIndex: -1 });
      continue;
    }

    generatedClothingData.push({ modelId: targetModel.id, quantity: 1, sizeIndex: targetSizeIndex });
  }

  return {
    errors,
    generatedClothingData
  };
};

type generateMissingClothingProductionDataParams = {
  generatedClothingData: ClothingProductionData[];
  importedModels: ClothingModelType[];
}

const generateMissingClothingProductionData = ({ generatedClothingData, importedModels }: generateMissingClothingProductionDataParams): ClothingProductionData[] => {
  const missingClothes: ClothingProductionData[] = [];

  for (let i = 0; i < importedModels.length; i++) {
    const currentModelId = importedModels[i].id;
    const generatedClothingIndex = generatedClothingData.findIndex(currentGeneratedClothe => currentGeneratedClothe.modelId === currentModelId);

    if (generatedClothingIndex > -1) continue;
    missingClothes.push({ modelId: currentModelId, quantity: 0, sizeIndex: -1 });
  }

  return missingClothes;
};

type ProcessingResult = {
  hasError: boolean;
  errorsFound: string[][];
  generatedOrders: Order[];
}

type ProcessParsedContentParams = {
  sublistUUID: string;
  listContentParsed: ListContentParsedResultData[];
  selectedClothesToGenerate: ClothingModelType[];
  importedModels: ClothingModelType[];
  preferences: UserPreferences;
  Translate: TFunction;
}

const sortGeneratedClothesInSameOrderAsImportedModels = (importedModels: ClothingModelType[], generatedClothingData: ClothingProductionData[]): ClothingProductionData[] => {
  const importedModelsIds = importedModels.map(model => model.id);

  const sortedClothingData: ClothingProductionData[] = [...generatedClothingData].sort((a, b) => {
    const idAIndex = importedModelsIds.indexOf(a.modelId);
    const idBIndex = importedModelsIds.indexOf(b.modelId);
    return idAIndex - idBIndex;
  });

  return sortedClothingData;
};

export const processParsedContent = ({ sublistUUID, listContentParsed, selectedClothesToGenerate, importedModels, preferences, Translate }: ProcessParsedContentParams): ProcessingResult => {
  const errorsFound: string[][] = [];
  const generatedOrders: Order[] = [];

  let fullClothingData: ClothingProductionData[] = [];

  const availableSizesInProjectForChildish = extractClothingSizesInGenderFromImportedModels(importedModels, 'childish');

  listContentParsed.forEach((row, rowNumber) => {
    const firstValidSizeToCheck = row.sizes?.find(size => size !== '');
    const sizeToCheck = firstValidSizeToCheck ?? '';

    const inferredGender = getGenderFromClothingSize({ size: sizeToCheck, preferences, availableSizesInProjectForChildish });

    const { errors, generatedClothingData } = validateInformations({
      selectedClothesToGenerate,
      sizes: row.sizes,
      gender: inferredGender,
      Translate
    });

    errorsFound.push(errors);

    // check clothes length if is equal the count of selected clothes to produce
    if (generatedClothingData.length < selectedClothesToGenerate.length || selectedClothesToGenerate.length < importedModels.length) {
      const missingClothes = generateMissingClothingProductionData({
        generatedClothingData,
        importedModels
      });

      fullClothingData = [...generatedClothingData, ...missingClothes];
    } else {
      fullClothingData = [...generatedClothingData];
    }

    const sortedClothingData = sortGeneratedClothesInSameOrderAsImportedModels(importedModels, fullClothingData);

    const newOrder: Order = {
      id: uuidv4(),
      sublistUUID,
      name: row.name ?? '',
      nickname: row.nickname ?? '',
      number: row.number ?? '',
      gender: inferredGender,
      bloodType: row.bloodType ?? '',
      clothes: [...sortedClothingData]
    };

    generatedOrders.push(newOrder);
  });

  const totalErrorsFound = errorsFound.reduce((accumulator, value) => {
    if (value.length > 0) return accumulator + 1;
    return accumulator;
  }, 0);

  const response: ProcessingResult = {
    hasError: totalErrorsFound > 0,
    errorsFound,
    generatedOrders
  };

  return response;
};
