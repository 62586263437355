/* eslint-disable @typescript-eslint/indent */
import _ from 'lodash';
import { Container, ImageFile } from '../../style';
import { useFormContext } from 'react-hook-form';
import { WrapperFile, RowMaterial, WrapperRawMaterial, WrapperForm, Content, WrapperInput } from './styles';
import { generatePathRegisterFile } from '../../../../../../../../helpers/general';
import IntlCurrencyInput from 'react-intl-currency-input';

type ProductsError = {
  products?: Record<
    number,
    {
      photo?: { message: string };
      title?: { message: string };
      description?: { message: string };
      price?: { message: string };
      width?: { message: string };
      type_meter?: { message: string };
      color?: { message: string };
    }
  >;
};

export function RawMaterial() {
  const methods = useFormContext();

  const key = 'products';
  const rawMaterialValue = methods.watch(key) ?? [];
  const errors = methods.formState.errors as ProductsError;

  return (
    <Container>
      <WrapperRawMaterial>
        {rawMaterialValue?.map((rawMaterial: any, index: number) => (
          <RowMaterial key={index}>
            <Content>
              <div>
                <WrapperFile>
                  {!_.isEmpty(rawMaterial.photo) ? (
                    <ImageFile
                      src={
                        typeof rawMaterial.photo === 'string'
                          ? generatePathRegisterFile({ filename: rawMaterial.photo })
                          : URL.createObjectURL(rawMaterial.photo[0])
                      }
                      alt='Imagem do cliente'
                    />
                  ) : (
                    <span>Adicionar foto</span>
                  )}

                  <input
                    type='file'
                    onChange={e => {
                      const file = e.target.files?.[0];
                      if (file) {
                        rawMaterialValue[index] = { ...rawMaterial, photo: [file] };
                        methods.setValue(key, rawMaterialValue);
                      }
                    }}
                  />
                </WrapperFile>
                {errors.products?.[index]?.photo && (
                  <div className='alert alert-danger mt-2'>{errors?.products?.[index]?.photo?.message}</div>
                )}
              </div>

              <WrapperForm>
                <div>
                  <label className='form-label'>Title</label>
                  <input
                    className={`form-control ${errors.products?.[index]?.title ? 'is-invalid' : ''}`}
                    type='text'
                    placeholder='Nome da matéria-prima'
                    value={rawMaterial.title}
                    onChange={e => {
                      rawMaterialValue[index] = { ...rawMaterial, title: e.target.value };
                      methods.setValue(key, rawMaterialValue);
                    }}
                  />
                </div>

                <div className='row'>
                  <div className='col-8'>
                    <label className='form-label'>Descrição</label>
                    <input
                      className={`form-control ${errors.products?.[index]?.description ? 'is-invalid' : ''}`}
                      type='text'
                      placeholder='Descrição da matéria-prima'
                      value={rawMaterial.description}
                      onChange={e => {
                        rawMaterialValue[index] = { ...rawMaterial, description: e.target.value };
                        methods.setValue(key, rawMaterialValue);
                      }}
                    />
                  </div>

                  <div className='col-4'>
                    <label className='form-label'>Cor</label>
                    <input
                      className={`form-control ${errors.products?.[index]?.color ? 'is-invalid' : ''}`}
                      type='text'
                      placeholder='Cor da matéria-prima'
                      value={rawMaterial.color}
                      onChange={e => {
                        rawMaterialValue[index] = { ...rawMaterial, color: e.target.value };
                        methods.setValue(key, rawMaterialValue);
                      }}
                    />
                  </div>
                </div>

                <div className='row'>
                  <div className='col'>
                    <label className='form-label'>Largura</label>
                    <input
                      className={`form-control ${errors.products?.[index]?.width ? 'is-invalid' : ''}`}
                      type='number'
                      placeholder='Largura da matéria-prima'
                      value={rawMaterial.width}
                      onChange={e => {
                        rawMaterialValue[index] = { ...rawMaterial, width: e.target.value };
                        methods.setValue(key, rawMaterialValue);
                      }}
                    />
                  </div>

                  <div className='col'>
                    <label className='form-label'>Unidade</label>
                    <select
                      className={`form-control ${errors.products?.[index]?.type_meter ? 'is-invalid' : ''}`}
                      value={rawMaterial.type_meter}
                      onChange={e => {
                        rawMaterialValue[index] = { ...rawMaterial, type_meter: e.target.value };
                        methods.setValue(key, rawMaterialValue);
                      }}
                    >
                      <option value=''>Selecione</option>
                      <option value='meter'>Metro</option>
                      <option value='liter'>Litro</option>
                      <option value='unit'>Unidade</option>
                    </select>
                  </div>

                  <div className='col'>
                    <label className='form-label'>Valor</label>
                    <WrapperInput className={`form-control ${errors.products?.[index]?.price ? 'is-invalid' : ''}`}>
                      <IntlCurrencyInput
                        currency='BRL'
                        config={{
                          locale: 'pt-BR',
                          formats: {
                            number: {
                              BRL: {
                                style: 'currency',
                                currency: 'BRL',
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                              }
                            }
                          }
                        }}
                        value={rawMaterial.price}
                        onChange={(_, value) => {
                          rawMaterialValue[index] = { ...rawMaterial, price: value };
                          methods.setValue(key, rawMaterialValue);
                        }}
                        defaultValue={0}
                        max={0}
                      />
                    </WrapperInput>
                  </div>
                </div>
              </WrapperForm>
            </Content>

            <div className='d-flex justify-content-end mt-3'>
              <button
                className='btn btn-danger'
                type='button'
                onClick={() => {
                  methods.setValue(
                    key,
                    rawMaterialValue.filter((_: any, i: number) => i !== index)
                  );
                }}
              >
                <i className='fa fa-trash mr-2'></i>
                Remover
              </button>
            </div>
          </RowMaterial>
        ))}
      </WrapperRawMaterial>

      <button
        className='btn btn-warning mt-3'
        type='button'
        onClick={() => {
          methods.setValue(key, [...rawMaterialValue, { photo: null }]);
        }}
      >
        <i className='fa fa-plus mr-2'></i>
        Adicionar matéria-prima
      </button>
    </Container>
  );
}
