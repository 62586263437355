import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-height: 480px;
  overflow-y: auto;
`;

export const WrapperContent = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 10px;
`;

export const WrapperItems = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  gap: 15px;
  border-bottom: 1px #f1f1f1 solid;

  img {
    width: 50px;
    height: 50px;
    border: 1px #f1f1f1 solid;
  }

  div {
    h6 {
      font-size: 1rem;
      margin: 0;
    }

    p {
      font-size: 0.875rem;
      margin: 0;
    }
  }
`;

export const WrapperInput = styled.div`
  padding: 0;
  input {
    border: none;
    border-radius: 0.35rem;
    outline: none;
    padding: 0.375rem 0.75rem;
    max-width: 100px;
  }
`;
